import React, { useState, useEffect, useCallback } from 'react';
import './SavedView.css';
import regenerateIcon from '../../resources/refresh-reload-svgrepo-com.svg'
import trashCan from '../../resources/trashCan.svg'
import exportPlan from '../../resources/export-plan.svg'
import SavedHeader from '../SavedHeader/SavedHeader.js';
import { useAuth } from '../../context/AuthContext.js';
import axios from 'axios';
import { usePlan } from '../../context/PlannerContext.js';

const SavedView = ({setIsOnSavedPlan}) => {
    const { user, refreshAccessToken } = useAuth();
    const { planAccordions, setPlanAccordions, recipeTemplate, checkifAnyPanelIsFilled } = usePlan();
    const [plans, setPlans] = useState(null);

    const getSavedPlans = useCallback(async () => {
        const token = localStorage.getItem('access');
        console.log(token)
        try{
            refreshAccessToken();
        }
        catch (error) {
            console.error("An error occurred:", error);
            alert("You're session has expired. caught in getSavecPlans");
        }
        try {
            const response = await axios.get('https://mealplannerapi.azurewebsites.net/api/get_saved_plans/', {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                'user': user
            });
            return response.data;
        } catch (error) {
            if (!navigator.onLine) {
                // Handle the case where the user is offline
                console.error("You're offline. Please check your internet connection.");
                alert("You're offline. Please check your internet connection.");
            } else {
                // Handle other errors
                console.error("An error occurred:", error);
                alert("You're session has expired. Please log in again.");
            }
        }
    },[user, refreshAccessToken]);

    const saveCurrentPlan = async () => {
        refreshAccessToken();
        console.log("saving plan...")
        
        if (!checkifAnyPanelIsFilled()){
            alert('Please fill out at least one meal to save.');
            return;
        }

        let planName = prompt("Name your saved plan");
        if (planName.trim() === '') {
            const now = new Date();
            const dateString = now.toLocaleDateString(undefined, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            });
            planName = `Unnamed Plan ${dateString}`;
        }

        const token = localStorage.getItem('access');
        try {
            const response = await axios.post('https://mealplannerapi.azurewebsites.net/api/save_plan/', {
                user: user,
                plan: planAccordions,
                name: planName
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            fetchPlans();
            return response;
        } catch (error) {
            if (!navigator.onLine) {
                // Handle the case where the user is offline
                console.error("You're offline. Please check your internet connection.");
                alert("You're offline. Please check your internet connection.");
            } else {
                // Handle other errors
                alert("Did not save, try again.");
            }
        }
    };

    const handlePlanDeleteClick = async (e) => {
        e.preventDefault();

        const planId = e.currentTarget.id;
        console.log("clicked plan id: ", planId);
        const name = e.currentTarget.name;

        const confirmDelete = window.confirm(`Do you want to delete plan ${name}?`);
        if (!confirmDelete) {
            return;
        }

        const token = localStorage.getItem('access');
        
        try {
            const response = await axios.post('https://mealplannerapi.azurewebsites.net/api/delete_plan/', {
                user: user,
                plan_id: planId
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Assuming you manage tokens in localStorage or context
                    'Content-Type': 'application/json'
                }
            });
            
            console.log(response.data.message);
            fetchPlans();

        } catch (error) {
            console.error("Error deleting plan:", error.response ? error.response.data : error.message);
        }

    };

    const handlePlanExportClick = async (e) => {
        e.preventDefault(); // Get the plan ID from the clicked button
        const planIndex = e.currentTarget.id; // Get the plan index from the clicked button
        console.log("exporting plan");
        // Find the plan with the matching ID
        console.log(plans)
        console.log(planIndex)
        const planToExport = plans[planIndex];
    
        if (!planToExport) {
            console.error("Plan not found");
            return;
        }
    
        // Assuming the plan's structure matches or is similar to what planAccordions expects
        // You might need to adjust the transformation based on the actual structure
        const transformedPlanAccordions = planToExport.days.map(day => {
            return {
                Breakfast: transformMeal(day, "Breakfast"),
                Lunch: transformMeal(day, "Lunch"),
                Dinner: transformMeal(day, "Dinner"),
                SnacksDessert: transformMeal(day, "SnacksDessert"),
            };
        });
    
        setPlanAccordions(transformedPlanAccordions);
        setIsOnSavedPlan(false);
    };
    
    // Helper function to transform each meal's data into the expected format
    const transformMeal = (day, mealType) => {
        const meal = day.meals.find(meal => meal.meal_type === mealType);
        const defaultRecipe = recipeTemplate();
    
        // Determine if this is a SnacksDessert meal
        const isSnacksDessert = mealType === "SnacksDessert";
    
        if (!meal || meal.recipes.length === 0) {
            return {
                special_instructions: '',
                servings: isSnacksDessert ? '0' : '1',
                isOpen: false,
                isFilled: false,
                isLoading: false,
                recipe: defaultRecipe,
            };
        }
    
        // Use the first recipe if available
        const recipe = meal.recipes[0];
        const isUnnamedRecipe = recipe.title.trim().toLowerCase() === 'unnamed recipe';
        
        // For SnacksDessert, isOpen is true if it's not an Unnamed Recipe, otherwise follows general rules
        const isOpenCondition = isSnacksDessert && isUnnamedRecipe ?  false : !isUnnamedRecipe ;
    
        return {
            special_instructions: '',
            servings: isSnacksDessert ? '0' : '1',
            isOpen: isOpenCondition,
            isFilled: !isUnnamedRecipe,
            isLoading: false,
            recipe: isUnnamedRecipe ? defaultRecipe : {
                title: recipe.title.trim(),
                ingredients: recipe.ingredients.trim(),
                instructions: recipe.instructions.trim(),
            },
        };
    };
    
    

    const fetchPlans = useCallback(async () => {

        const receivedPlans = await getSavedPlans();
        setPlans(receivedPlans);
    }, [getSavedPlans]); 
    
    useEffect(() => {
        fetchPlans();
    }, [fetchPlans]);

    return (
        <>
        
        <SavedHeader saveCurrentPlan={saveCurrentPlan}/>
        <div className="saved-section">
          
            {
                plans === null ? (
                    <img className="loading-svg" src={regenerateIcon} alt="Loading..."></img>
                ) : plans.length === 0 ? (
                    <p>No saved plans yet!</p>
                ) : (
                    plans.map((plan, index) => (
                        <div key={index} className="saved-plan" >
                                <h3 className="saved-plan-title">{plan.name}</h3>
                            
                            <div className="saved-recipes"> 
                                {plan.days.flatMap(day => day.meals.flatMap(meal => meal.recipes))
                                    .filter(recipe => recipe.title.trim() !== 'Unnamed Recipe')
                                    .slice(0, 5)
                                    .map((recipe, recipeIndex) => (
                                        <p className="saved-plan-recipe" key={recipeIndex}>{recipe.title.trim()}</p>
                                ))}
                            </div>
                            <div className="saved-plan-buttons-container">
                                <img className="saved-plan-icon" src={exportPlan} alt="Export Plan"  id={index} name={plan.name} onMouseDown={handlePlanExportClick}/>
                                <img className="saved-plan-icon" src={trashCan} alt="Delete Plan" id={plan.id} name={plan.name} onMouseDown={handlePlanDeleteClick}/>
                            </div>
                            
                        </div>
                    ))
                )
            }
        </div>
        </>
      );
};

export default SavedView;