import React, {useRef, useState, useEffect} from 'react';
import './Accordion.css'
import { usePlan }  from '../../context/PlannerContext.js';
import { useAuth  } from '../../context/AuthContext.js';
import playButton from '../../resources/play-button-svgrepo-com.svg'
import cross from '../../resources/cross-svgrepo-com.svg'
import regenerate from '../../resources/refresh-reload-svgrepo-com.svg'

function Accordion({dayNumber, meal}){

    const { planAccordions, getPlanAccordion, fetchPlan, setPlanAccordions, setPlanAccordion, demoCredits, setDemoCredits, setIsPopupOpen } = usePlan();
    const modalRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [currentMeal, setCurrentMeal] = useState(null);
    const { isSubscribed } = useAuth();
    
    const generatePlaceholderText = (meal, day) => {
        const placeholders = {
          Breakfast: 'Special Request e.g., "Smoothie"',
          Lunch: 'e.g., "Use leftover boiled egg"',
          Dinner: 'e.g, "Authentic Korean"',
          SnacksDessert: ''
        };
    
        return day > 1 ? '' : placeholders[meal] || '';
    };

    const removeIthDay = (i) => {
        if (planAccordions.length > 1) {
            setPlanAccordions(planAccordions.slice(0, i).concat(planAccordions.slice(i+1)));
        }
    };

    const toggleMeal = (meal) => () => {
        let val = !getPlanAccordion(dayNumber-1, meal, 'isOpen')
        setPlanAccordion(dayNumber-1, meal, val, 'isOpen');
        setPlanAccordion(dayNumber-1, meal, val ? '1' : '0', 'servings');
    
        const allMealsClosed = Object.values(planAccordions[dayNumber-1]).every(meal => !meal.isOpen);
        if (allMealsClosed && planAccordions.length > 1) {
          // Remove the day
          removeIthDay(dayNumber-1);
        }
    };

    const handleRegenerateClick = () => {
        setPlanAccordion(dayNumber-1, meal, false, 'isFilled');
        // setForceUpdate(!forceUpdate); // Toggle forceUpdate to trigger a re-render
    };

    const handleSinglePaneGenerate = async () => {

        if (!isSubscribed){

            if (demoCredits <= 0){
                alert("Demo limit reached. Please subscribe to continue.");
                setIsPopupOpen(true);
                return;
            }else{
                setDemoCredits(demoCredits-1);
            }

        };

        

        setPlanAccordion(dayNumber-1, meal, true, 'isLoading');

        try {
            const response = await fetchPlan(dayNumber,meal);
            setPlanAccordion(dayNumber-1, meal, response, 'recipe');
            setPlanAccordion(dayNumber-1, meal, true, 'isFilled');
            console.log(getPlanAccordion(dayNumber-1, meal, 'isFilled'))
        } catch (error) {
            console.error("Error submitting form: ", error);
        }
        
        setPlanAccordion(dayNumber-1, meal, false, 'isLoading');
    };

    const handleKeyDownAnywhere = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            setShowModal(false);
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div key={meal} className="accordion-section" id={meal}>

            {/* Heading */}
            <div className="accordion-heading" >
                <div className="meal-title">
                {meal === "SnacksDessert" ? "Snacks/Dessert" : meal}
                </div>
                {/* Actions */}
                <span className="action-container" >
                    <div className="play-button">
                    {getPlanAccordion(dayNumber-1, meal, 'isOpen') ? 
                        (getPlanAccordion(dayNumber-1, meal, 'isFilled') || getPlanAccordion(dayNumber-1, meal, 'isLoading') ? 
                                    <img 
                                        src={regenerate} 
                                        alt="Regenerate" 
                                        className={`regenerate ${getPlanAccordion(dayNumber-1, meal, 'isLoading') ? 'isLoading' : ''}`}
                                        onClick={handleRegenerateClick} />
                                  : <img 
                                        src={playButton}
                                        alt="Play" 
                                        className="play-btn"
                                        onMouseDown={handleSinglePaneGenerate} />
                        )
                    : <div/>}
                    </div>
                    <div className="toggle-icon" onClick={toggleMeal(meal)}>
                    {getPlanAccordion(dayNumber-1, meal, 'isOpen') ? <img src ={cross} className="unrotate45" alt="X"/> : <img src={cross} className ="rotatecounterclockwise45" alt="+"/>}
                    </div>
                </span>
            </div>

            {/* Panel */}
            {getPlanAccordion(dayNumber-1, meal, 'isOpen') && !getPlanAccordion(dayNumber-1, meal, 'isFilled') && (
                <div className={`accordion-panel ${getPlanAccordion(dayNumber-1, meal, 'isOpen') ? 'open' : 'closed'}`}>
                    <input
                        type="text"
                        placeholder={generatePlaceholderText(meal,dayNumber)}
                        className="accordion-input"
                        value={planAccordions[dayNumber-1][meal].special_instructions}
                        onChange={e => setPlanAccordion(dayNumber-1, meal, e.target.value, 'special_instructions')}
                        onKeyDown={handleKeyDownAnywhere}
                    />
                    <div>
                        <span className="servings-title">
                            {"Servings:  "}
                        </span>
                        <span>
                        <input
                            type="number"
                            className="servings-input"
                            min="0"
                            max="10"
                            defaultValue={1}
                            onChange={e => setPlanAccordion(dayNumber-1, meal, e.target.value, 'servings')}
                            onKeyDown={handleKeyDownAnywhere}
                        />
                        </span>
                    </div>
                </div>
            )}
            {getPlanAccordion(dayNumber-1, meal, 'isOpen') && getPlanAccordion(dayNumber-1, meal, 'isFilled') && (
                <div className='plan-meal-section'>
                    <p className='recipe-title' onMouseDown={(e) => {
                        e.stopPropagation();
                        if (showModal && currentMeal === meal) {
                            setShowModal(false);
                            setCurrentMeal(null);
                        } else {
                            setShowModal(true);
                            setCurrentMeal(meal);
                        }
                    }}>{getPlanAccordion(dayNumber-1, meal, 'recipe','title')}</p>
                    {showModal && currentMeal === meal && (
                        <div ref={modalRef} className="accordion-content open">
                            <p>Ingredients: {getPlanAccordion(dayNumber-1, meal, 'recipe','ingredients') && getPlanAccordion(dayNumber-1, meal, 'recipe','ingredients').split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}</p>
                            <p>Instructions: {getPlanAccordion(dayNumber-1, meal, 'recipe','instructions') && getPlanAccordion(dayNumber-1, meal, 'recipe','instructions').split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Accordion;