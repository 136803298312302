import React from 'react';
import './SubscribePopUp.css';
import cross from '../../resources/cross-svgrepo-com.svg';
import { usePlan } from  '../../context/PlannerContext';
import { useNavigate } from 'react-router-dom';

const SubscribePopup = () => {

    const {isPopupOpen, setIsPopupOpen} = usePlan();
    const navigate = useNavigate();
    if (!isPopupOpen) 
        return;

   const handleClosePopup = () => {
        setIsPopupOpen(false);
   };

   const handleSignUpClick = () => {
        setIsPopupOpen(false);
        navigate('/SignUp');
   }

    return (
        <div className="subscribePopup"> 
            <div onMouseDown={handleClosePopup} className="xbutton"><img src={cross} alt="exit" /></div>
            
            
            <h3 className="subscribeButton" onMouseDown={handleSignUpClick}>Sign Up!</h3>
            
            <ul className="featuresList">
                <li>Unlimited Generate Credits</li>
                <li>Save Your Favorite Plans</li>
                <li>Plan Up To 8 Days At A Time</li>
                <li>Amazon Fresh and Whole Foods Integration Coming Soon!</li>
            </ul>
        </div>
    );
};

export default SubscribePopup;
