import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import betaIcon from '../../resources/beta-icon.svg';
import { useAuth } from '../../context/AuthContext';

const Header = () => {
    const { user, logout } = useAuth(); // Destructure logout from useAuth
    const navigate = useNavigate();

    const handleSignOut = () => {
        try {
            logout();
            console.log("logout successful");
        } catch (error) {
            console.error('Logout failed:', error);
            navigate('/');
        }
    };

    console.log("User is now: ", user)

    return (
        <header className="hero-header">
            <Link to="/" className='title-wrapper'>
                <h1 className="header-title-long">Le Petit Planner</h1>
                <h1 className="header-title-short">Le Petit</h1>
                <h1 className="header-title-mini">Lp</h1>
                <img src={betaIcon} alt="beta" className='beta-icon'/>
            </Link>
            <nav>
                <ul>
                    <li key="about-us"><Link to="/AboutUs">Contact</Link></li>
                    {user ? (
                        <li key="sign-out" onMouseDown={handleSignOut}>Sign Out</li>
                    ) : (
                        <li key="sign-in"><Link to="/SignIn">Sign In</Link></li>
                    )}
                </ul>
            </nav>
        </header>
    );
}

export default Header;
