import React from 'react';
import './ContactUs.css';
function Recipe() {

  return (
    <>
        <div className='contact-box'>
            <h3>Email: worldsbestmealplanner@gmail.com</h3>
        </div>

    </>
  );
}

export default Recipe;