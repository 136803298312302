// SignUpForm.js
import React, { useState, useEffect} from 'react';
import './SignUpForm.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext.js';
import checkMark from '../../resources/checkMark.svg';
import regenerateIcon from '../../resources/refresh-reload-svgrepo-com.svg';

const SignUpForm = () => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirm_password: ''
    });

    const { login, user, isSubscribed } = useAuth();
    const [showPricingTable, setShowPricingTable] = useState(user && !isSubscribed ? true : false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (user && isSubscribed) {
            navigate('/planner');
        }
    },[user, isSubscribed, navigate]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };


    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        if (formData.confirm_password !== formData.password)
            return alert('Passwords do not match');

        
        try {
            const response = await axios.post('https://mealplannerapi.azurewebsites.net/api/register/', formData); // Adjust URL accordingly
            
            login(formData.username, formData.password);
            if (response.status === 201) {
                setShowPricingTable(true);
            }
            setIsLoading(false);
            return response.status === 201 ? '': alert('Account creation failed');
        } catch (error) {
            setIsLoading(false);
            document.getElementsByClassName('error-container')[0].style.padding = '3px';
            document.getElementsByClassName('error-container')[0].innerHTML = error.response.data.error;

        }
        
    };

    return (
        <>
        <h2 className="sign-up-title">Endless Inspiration...</h2>
        <div className="display-wrapper">

        
            <div className="form-wrapper">
                <h3 className="card-title">Join Us</h3>
             
                
                {user ? (
                    <div className={`success-container ${isLoading ? 'hidden' : ''}`}>
                        <img src={checkMark} alt="checkmark" className="check-icon"/>
                        You are logged in!
                    </div>
                ) : (
                    <>
                    <div className={`loading-container ${isLoading ? '' : 'hidden'}`}>
                    <img src={regenerateIcon} alt="refresh" className={`loading-svg ${isLoading ? '' : 'hidden'}`} />
                    </div>

                    <form onSubmit={handleSubmit} className={`form-container ${isLoading ? 'hidden' : ''}`}>
                        <div className="panel-title">Username</div>
                        <input type="text" name="username" placeholder="Username" value={formData.username} className="sign-up-form-input" onChange={handleChange} />
                        
                        <div className="panel-title">Email</div>
                        <input type="email" name="email" placeholder="Email" value={formData.email} className="sign-up-form-input" onChange={handleChange} />
                        
                        <div className="panel-title">Password</div>
                        <input type="password" name="password" placeholder="Password" value={formData.password} className="sign-up-form-input" onChange={handleChange} />
                        
                        <div className="panel-title">Confirm Password</div>
                        <input type="password" name="confirm_password" placeholder="Confirm Password" value={formData.confirm_password} className="sign-up-form-input" onChange={handleChange} />
                        
                        <button type="submit" className="submit-button">Sign Up</button>
                        <div className="error-container"></div>
                        <div className="have-accountq-text">Already have an account?</div> 
                        <Link to="/SignIn" className="sign-in">Sign in</Link>
                    </form>
                    </>
                )}

                
            </div>

        </div>

        { showPricingTable && 
            <>
            <h2 className="sign-up-title-2">Love What You Eat!</h2>
            <stripe-pricing-table pricing-table-id="prctbl_1P3X5CGqhHDBOex2bWFVLBwg"
            publishable-key="pk_live_51OwsBxGqhHDBOex2PeCE802itBkueg4NDiRPAKTxgPeKpDtZGbuFeFDppz2ZkWvHCpaRxHkw2QZiPAoyJeL1Sffc00R6Cw8COK">
            </stripe-pricing-table>
            <div className="explainer-bar-signup">
                <h1 className="explainer-text">{`Be Sure To Always Use The Same Email :-)`}</h1>
            </div>
            </>
        }
        </>
    );
};

export default SignUpForm;


