import React from 'react';
import './HeroPage.css'
import ScrollingVeg from '../ScrollingVeg/ScrollingVeg';
import { useAuth } from '../../context/AuthContext';
import { usePlan }  from '../../context/PlannerContext';
import { Link } from 'react-router-dom';


const HeroPage = ({ calendarRef }) => {
    const {user, isSubscribed} = useAuth(); 
    const { setIsPopupOpen } = usePlan();
    
    const handleButtonClick = () => {
        if (user && !isSubscribed){
            setIsPopupOpen(true);
        }
        if (calendarRef.current) {
            const elementPosition = calendarRef.current.getBoundingClientRect().top;
            const offsetPosition = elementPosition - window.innerHeight / 4;
    
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className="landing-window">
            <h2 className="hero-text">The World's Best AI Meal Planner</h2>
            {user && isSubscribed ? (
                <Link to="/planner" className="text">
                <button className="goto-planner-button">Go To Planner</button>
                </Link>
            ) : (
                <div onMouseDown={handleButtonClick}>
                    <button className="goto-planner-button" >Try Planner</button>
                </div>
            )}
            <div className="hero-sub-text">Plan, Shop, Order. </div>
            
            <ScrollingVeg className="veg-scroll"/>
        </div>
        
    )
}

export default HeroPage;
