import React from "react";
import Header from '../Header/Header.js';
import { Outlet } from 'react-router-dom'; // Assuming you're using react-router

const Layout = () => {// You need to determine this value

  return (
    <div>
        <Header/>
        <Outlet /> {/* This is where HomePage, AboutPage, etc., get rendered */}
        {/* Footer, etc., common to all pages */}
    </div>
  );
};

export default Layout;
