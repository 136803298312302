import React from 'react';
import './ScrollingVeg.css'; // Import the CSS file for styling // Import the image file

const ScrollingVeg = () => {
  return (
    <div className="banner">
    </div>
  );
};

export default ScrollingVeg;