import React from 'react';
import './NavBar.css';
import Star from '../../resources/star.svg';
import InstacartLogo from '../../resources/instacart-mini-logo.svg';
import amznFrsh from '../../resources/amazon-fresh.svg'
import exportIcon from '../../resources/export.svg'
import plannerIcon from '../../resources/planner.svg'
import { usePlan } from '../../context/PlannerContext.js';
import { jsPDF } from 'jspdf';
import { useAuth } from '../../context/AuthContext.js';
import { useNavigate } from 'react-router-dom';

const NavBar = ({isAllAccess, setIsOnSavedPlan,isOnSavedPlan}) => {

    const { scrollToLastDay, checkifAnyPanelIsFilled, planAccordionTemplate, planAccordions, setPlanAccordions, setIsPopupOpen } = usePlan();
    const { user, isSubscribed } = useAuth();
    const navigate = useNavigate();
     // Plus button
    const addDay = () => {
        if (isSubscribed){
            if (planAccordions.length < 8) {
                setPlanAccordions([...planAccordions, planAccordionTemplate()]);
            }
        }else{
            if (planAccordions.length < 4) {
                setPlanAccordions([...planAccordions, planAccordionTemplate()]);
            }
        }
        
    };

    // Minus Button
    const removeLastDay = () => {
        if (planAccordions.length > 1) {
            setPlanAccordions(planAccordions.slice(0, -1));
        }
    };

    const handleSaveClick = () => {
        console.log("Save Clicked");
        if (user && isAllAccess){
            setIsOnSavedPlan(!isOnSavedPlan);
        }
        else if (user && !isAllAccess){
            navigate('/planner');
        }
        else{
            setIsPopupOpen(true); 
        }
            
    };

    const handleIngredientsRender = async () => {
        if (!checkifAnyPanelIsFilled()){
            alert("Please generate a meal plan first.")
            return;
        }

        let ingredients = [];
    
        planAccordions.forEach(day => {
            Object.values(day).forEach(meal => {
                if (meal.isFilled && meal.isOpen) {
                    const ingredientList = meal.recipe.ingredients.split(/-\s+/);
                    
                    ingredientList.forEach(ingredient => {
                        // Remove the dash and whitespace from the start of the ingredient
                        const cleanedIngredient = ingredient.replace(/^- /, '').trim();
                        if (cleanedIngredient) {
                            ingredients.push(cleanedIngredient.concat('#'));
                                
                        }
                    });
                }
            });
        });

        // Encode the ingredients list into a JSON string, then to base64
        ingredients = ingredients.join('');
        const encodedText = btoa(encodeURIComponent(ingredients));
        // To make it URL-safe, you might replace '+' and '/' from standard Base64 encoding
        const urlSafeEncodedText = encodedText.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

        // Construct the URL with the encoded ingredients part of the path
        const url = `https://mealplannerapi.azurewebsites.net/api/ingredients/${urlSafeEncodedText}`;
        
        window.open(url);
    };

    const handleAFRender = () => {
        alert("Amazon Fresh is coming soon! Please use Instacart for now.")
    };

    const handlePDFExport = () => {
        const doc = new jsPDF();

        if (!checkifAnyPanelIsFilled()){
            alert("Please generate a meal plan first.")
            return;
        }

        // doc.setFont('NothingYouCouldDo');
        doc.setFont('NothingYouCouldDo');
        doc.setFontSize(22); 
        doc.text('LE PETIT MEAL PLAN', 105, 80, 'center');
        doc.setFontSize(16); // Medium font
        const today = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        });
        doc.text(`created on ${today}, powered by GrazeAI`, 105, 100, 'center');
        
        planAccordions.forEach(day => {
            Object.entries(day).forEach(([mealName, meal]) => {
                if (meal.isFilled && meal.isOpen) {
                    doc.addPage();
                    doc.setFontSize(20);
                    const title = meal.recipe.title;
                    const ingredientList = meal.recipe.ingredients.split(/-\s+/) 
                    const instructions = meal.recipe.instructions;
        
                    doc.text(`Day ${planAccordions.indexOf(day) + 1}: ${mealName}`, 105, 10, 'center');
                    doc.text(title, 105, 20, 'center');
        
                    doc.setFontSize(16);
                    doc.text('Ingredients:', 15, 40); 
                    var counter = 0;
                    ingredientList.forEach(ingredient => {
                        const cleanedIngredient = ingredient.replace(/^- /, '').trim();
                        if (cleanedIngredient) {
                            doc.text(cleanedIngredient, 20, 50+counter);
                            counter=counter+6;
                        }
                    });
        
                    // Dynamically calculate the starting position for instructions based on ingredients
                    const instructionsStartY = 50 + (ingredientList.length * 6); // Adjust spacing based on your needs
        
                    // Instructions
                    doc.setFontSize(16);
                    doc.text('Instructions:', 10, instructionsStartY);
                    const instructionsLines = doc.splitTextToSize(instructions, 170); // Adjust width as needed
                    doc.text(instructionsLines, 20, instructionsStartY + 10);
                }
            });
        });
        
        const today_filename = new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        });
        doc.save(`Le_Petit_Meal_Plan_${today_filename}.pdf`);
        
    };

    return(
        <nav className="nav-bar">
            
            <div className="icon-container" onMouseDown={handleIngredientsRender}>
                <img className="icon" src={InstacartLogo} alt="Instacart"/>
                <div className="icon-text">Instacart</div>
            </div>
            <div className="icon-container" onMouseDown={handleAFRender}>
                <img className="icon" src={amznFrsh} alt="Amzn Fresh" />
                <div className="icon-text">Fresh</div>
            </div>
            {/* <div className="plus-minus-container"> */}
                <div className="icon-container" onMouseDown={removeLastDay}> 
                    <div className="remove-day-button"  onMouseUp={scrollToLastDay}>-</div>
                    <div className="icon-text">Remove Day</div>
                </div> 
                <></>
                <div className="icon-container" onMouseDown={addDay}> 
                    <div className="add-day-button"  onMouseUp={scrollToLastDay}>+</div>
                    <div className="icon-text">Add Day</div>
                </div> 
            {/* </div> */}

            <div className="icon-container" onMouseDown={handleSaveClick}>
                <img className ="icon" src={ isOnSavedPlan ? plannerIcon : Star}alt="Saved"/>
                <div className="icon-text">{`${isOnSavedPlan ? "Planner" : "Saved"}`}</div>
            </div>

            <div className="icon-container" onMouseDown={handlePDFExport}>
                <img className="icon" src={exportIcon} alt="Export"/>
                <div className="icon-text">PDF</div>
            </div>    
            
        </nav>
        
    )
};

export default NavBar;