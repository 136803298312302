import React from 'react';
import './AccordionContainer.css';
import Accordion from '../Accordion/Accordion';

// Use React.forwardRef to forward the ref
const AccordionContainer = React.forwardRef(({ dayNumber}, ref) => {
  
  const meals = ['Breakfast', 'Lunch', 'Dinner', 'SnacksDessert'];

  return (
    <div className="day-container" ref={ref}> {/* Attach the forwarded ref here */}
      <div className="day-title" >Day {dayNumber}</div>
      {meals.map(meal => {
        return (
          <Accordion 
            key={meal}
            dayNumber={dayNumber}
            meal={meal}
          />
        );
      })}
    </div>
  );
});

export default AccordionContainer;
