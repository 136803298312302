import React from "react";
import { Link } from "react-router-dom";
import { usePlan } from "../../context/PlannerContext";
import "./PlannerHeader.css";

const PlannerHeader = () => {
    const { specialRequests, setSpecialRequests } = usePlan();

    

    const handleSpecialRequestChange = (e) => {
        setSpecialRequests(e.target.value);
    };


    return (
        <header className="planner-header">
            <div className="header-content">
            
                <Link to="/" className='title'>
                    <h1 className="mini-title">Lp</h1>
                </Link>


                <div className = "special-requests-header">
                    <input type="text" placeholder="Special Requests" value={specialRequests} className="special-requests-bar" onChange={handleSpecialRequestChange}/>
                </div>
            
            </div>
        </header>
    );
};

export default PlannerHeader;