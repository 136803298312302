import React, {useState, useEffect} from "react";
import './GenerateButton.css';
import regenerate from "../../resources/refresh-reload-svgrepo-com.svg";
import playIcon from "../../resources/play-button-svgrepo-com.svg";
import{ usePlan } from '../../context/PlannerContext';

const GenerateButton = () => {

    const { handleSubmit, isAnyPanelLoading } = usePlan();

    const [loadingText, setLoadingText] = useState('Generating...');

    // handle isLoading animation
    useEffect(() => {
        let intervalId;
        let wordCount = 0;

        if (isAnyPanelLoading) {
            let dotCount = 0;
            const words = [ 'Good', 'Things', 'Are', 'Coming','One', 'Moment'];
            intervalId = setInterval(() => {
                dotCount = (dotCount + 1) % 4;
                if (dotCount === 0)
                {
                    wordCount = (wordCount + 1) % words.length;
                } 
                const word = words[wordCount];
                const dots = '.'.repeat(dotCount % 3);
                const spaces = ' '.repeat(3 - dotCount % 4);
                setLoadingText(`${word}${dots}${spaces}`);
            }, 500);
        } else {
            setLoadingText('Generate');
        }
    
        return () => {
        if (intervalId) {
            clearInterval(intervalId);
        }
        };
    }, [isAnyPanelLoading]);
    

    return(
        <button type="submit" className="submitBtn" onSubmit={handleSubmit} disabled={isAnyPanelLoading}>
            { isAnyPanelLoading 
            ? <img className="generate-spin" src={regenerate} alt="Generating"/>
            : <img className="play-icon" src={playIcon} alt="Generate"/>
            }
            <div className="generate-text">{isAnyPanelLoading ? loadingText : "Generate"} </div>
        </button>
    )
};

export default GenerateButton;