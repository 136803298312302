// SignUpForm.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SignInForm.css';
import { useAuth } from '../../context/AuthContext';
import checkMark from '../../resources/checkMark.svg';


const SignInForm = () => {
    
    const navigate = useNavigate();
    const {  user, login } = useAuth(); 

    useEffect(() => {
        if (user) {
            navigate('/planner');
        }
    }, [user, navigate]);


    const [formData, setFormData] = useState({
        username_or_email: '',
        password: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await login(formData.username_or_email, formData.password);
        } catch (error) {
            console.log(error.response.data.error);
            document.getElementsByClassName('error-container')[0].innerHTML = error.response.data.error;
        }
    };

    

    return (
        <>
        <h2 className="sign-up-title">I missed you.</h2>
        
        
        <div className="display-wrapper">

        
            <div className="form-wrapper">
                <h3 className="card-title">Sign In!</h3>
                
                {user ? 
                    <div className="success-container">
                        <img src={checkMark} alt="checkmark" className="check-icon"/>
                        You are logged in!
                    </div>

                :

                <form onSubmit={handleSubmit} className="form-container">

                    <div className="panel-title">Username or Email</div>
                    <input type="text" name="username_or_email" placeholder="Username or Email" value={formData.username_or_email} className="sign-up-form-input" onChange={handleChange} />
                    <div className="panel-title">Password</div>
                    <input type="password" name="password" placeholder="Password" value={formData.password} className="sign-up-form-input" onChange={handleChange} />
                    <button type="submit" className='submit-button'>Sign In</button>
                    <div className ="help-container">
                    <div className="have-accountq-text">
                            Forgot Your Password? 
                        </div>
                        <Link to="/RecoverPassword" className="sign-in-extras">Reset Password</Link>
                        <div className="have-accountq-text">
                            Don't have an account?
                        </div>
                        <Link to="/SignUp" className="sign-in-extras">Sign up.</Link>
                        
                    </div>
                    <div className="error-container"></div>
                </form>
                } 
                
            </div>
        </div>
        </>
    );
};

export default SignInForm;
