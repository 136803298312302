import React, { useState } from 'react';
import axios from 'axios';
import './RecoverPassword.css';

const PasswordResetForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showCodeForm, setShowCodeForm] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(email);
    const data = { 'email': email };
    try {
      const response = await axios.post('https://mealplannerapi.azurewebsites.net/api/password_reset/', data );
      console.log(response);
      setMessage(response.data.message); // Assuming the backend sends back a message
      setShowCodeForm(true);
    } catch (error) {
      setMessage('Failed to send password reset email. Please try again.');
    }
  };

  return (
    <>
    <form className="reset-form" onSubmit={handleSubmit}>
      <h2>Reset Password</h2>
      <div>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <button type="submit">Send Reset Email</button>
      {message && <p>{message}</p>}
      
    </form>
    {showCodeForm && <CodeForm email = {email} setShowResetForm={setShowResetForm}/>}
    {showResetForm && <NewPasswordForm email = {email} />}

    </>
  );
};

const CodeForm = ({setShowResetForm, email}) => {
    const handleCodeCheck = async (e) => {
        e.preventDefault();
        const code = document.getElementById('code').value;
        const data = { 'email': email, 'code': code};
        try {
            const response = await axios.post('https://mealplannerapi.azurewebsites.net/api/password_reset/validate/', data);
            console.log(response);
            if (response.data.status === 'success'){
                setShowResetForm(true);
            }
            else {
                alert('Invalid code. Please try again.');
            }
        } catch (error) {
            alert('Invalid code. Please try again.');
        }
    }

    return (
        <form className="code-enter" onSubmit={handleCodeCheck}>
            <h3>Enter Code</h3>
            <div>
                <label htmlFor="code">Code:</label>
                <input type="text" id="code" required />
            </div>
            <button type="submit">Submit Code</button>
        </form>
    )
};

const NewPasswordForm = ({email}) => {
    const handleNewPasswords = async (e) => {
        e.preventDefault();
        const password = document.getElementById('password').value;
        const confirm_password = document.getElementById('confirm_password').value;
        if (password !== confirm_password) {
            alert('Passwords do not match');
            return;
        }
        const data = { 'email': email, 'password': password };
        try {
            const response = await axios.post('https://mealplannerapi.azurewebsites.net/api/password_reset/confirm/', data);
            alert(response.data.message);
        } catch (error) {
            alert('Failed to reset password. Please try again.');
        }
    };

    return (
        <form className="enter-new-passwords" onSubmit={handleNewPasswords}>
            <h3>Enter New Password</h3>
            <div>
                <label htmlFor="password">New Password:</label>
                <input type="password" id="password" required />
            </div>
            <div>
                <label htmlFor="confirm_password">Confirm Password:</label>
                <input type="password" id="confirm_password" required />
            </div>
            <button type="submit">Reset Password</button>
        </form>
    )
};
export default PasswordResetForm;
