import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useAuth } from './AuthContext';
import axios from 'axios';

const PlanContext = createContext();
export const usePlan = () => useContext(PlanContext);

export const PlanProvider = ({ children }) => {

    const { isSubscribed } = useAuth();

    const recipeTemplate = () => ({
        title:'',
        ingredients:'',
        instructions:'',
    });

    const planAccordionTemplate = () => ({
        Breakfast: { special_instructions: '', servings: '1', isOpen: true, isFilled: false, isLoading: false, recipe:recipeTemplate },
        Lunch: { special_instructions: '', servings: '1', isOpen: true, isFilled: false, isLoading: false, recipe:recipeTemplate },
        Dinner: { special_instructions: '', servings: '1', isOpen: true, isFilled: false, isLoading: false, recipe:recipeTemplate },
        SnacksDessert: { special_instructions: '', servings: '0', isOpen: false, isFilled: false, isLoading: false, recipe:recipeTemplate },
    });
    
    // State variables
    const [planAccordions, setPlanAccordions] = useState(Array(3).fill().map(planAccordionTemplate));
    const [specialRequests, setSpecialRequests] = useState('');
    const [isAnyPanelLoading, setIsAnyPanelLoading] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [demoCredits, setDemoCredits] = useState(localStorage.getItem('LPdemoCredits') || 36); 

    const setPlanAccordion = (i, meal, value, key, recipeAttribute) => {
        const newPlanAccordions = [...planAccordions];
        if (recipeAttribute) {
            newPlanAccordions[i][meal][key][recipeAttribute] = value;
        } else {
            newPlanAccordions[i][meal][key] = value;
        }
        setPlanAccordions(newPlanAccordions);
    };

    useEffect(() => {
        localStorage.setItem('LPdemoCredits', demoCredits);
    }, [demoCredits]);

    //given dayNumber and meal, send request to API for recipe
    const fetchPlan = async (dayNumber, meal) => {

        let special_instructions = getPlanAccordion(dayNumber-1, meal, 'special_instructions');
        let servings = getPlanAccordion(dayNumber-1, meal, 'servings');

        const requestData = {
            mealType: meal,
            special_instructions: special_instructions,
            servings: servings,
            specialRequests: specialRequests,
        };  

        try {
            const response = await axios.post('https://mealplannerapi.azurewebsites.net/api/submit/', requestData);
            return response.data.message;
        } catch (error) {
            if (!navigator.onLine) {
                // Handle the case where the user is offline
                console.error("You're offline. Please check your internet connection.");
                alert("You're offline. Please check your internet connection.");
            } else {
                // Handle other errors
                console.error("An error occurred:", error);
                alert("An error occurred. Please try again later.");
            }
        } 
    };

    const getPlanAccordion = (i, meal, key, recipeAttribute) => {
        if (recipeAttribute) {
            return planAccordions[i][meal][key][recipeAttribute];
        }
        return planAccordions[i][meal][key];
    };
    


    const checkifAnyPanelIsLoading = useCallback(() => {
        for (let i = 0; i < planAccordions.length; i++) {
            for (let mealType in planAccordions[i]) {
                if (planAccordions[i][mealType].isLoading) {
                    return true;
                }
            }
        }
        return false;
    }, [planAccordions]);

    const checkifAnyPanelIsFilled = useCallback(() => {
        for (let i = 0; i < planAccordions.length; i++) {
            for (let mealType in planAccordions[i]) {
                if (planAccordions[i][mealType].isFilled) {
                    return true;
                }
            }
        }
        return false;
    }, [planAccordions]);

    //full planner form submit
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isSubscribed ){
            if (demoCredits <= 0){
                alert("Demo limit reached. Please subscribe to continue.");
                setIsPopupOpen(true);
                return;
            }
        }

        

        setAllOpenPanelsToLoading(); //start loading animation for open panels
        
        let localDemoCredits = demoCredits; 
        
        for (let i = 0; i < planAccordions.length; i++) {
            for (let mealType in planAccordions[i]) {

                if (!isSubscribed ){
                    if (localDemoCredits <= 0){
                        setPlanAccordion(i, mealType, false, 'isLoading');
                        setDemoCredits(localDemoCredits);
                        console.log("Demo limit reached");
                        continue;
                    }
                    else{
                        localDemoCredits -= 1;
                        setDemoCredits(localDemoCredits);
                    }

                }
                
    
                if (getPlanAccordion(i, mealType, 'servings') >= 1 && getPlanAccordion(i, mealType, 'isOpen')) {

                    try {
                        const response = await fetchPlan(i+1, mealType);
                        setPlanAccordion(i, mealType, response, 'recipe'); //fill recipe
                        setPlanAccordion(i, mealType, true, 'isFilled'); //set isFilled to true
                        setIsAnyPanelLoading(checkifAnyPanelIsLoading()); //update isAnyPanelLoading state
                        
                    } catch (error) {
                        console.error("Error submitting form: ", error);
                    }  
                }
                setPlanAccordion(i, mealType, false, 'isLoading');
            }
        }
        setIsAnyPanelLoading(checkifAnyPanelIsLoading());
        if (!isSubscribed){
            setDemoCredits(localDemoCredits);
        }
        console.log("end of handeSubmit")
    };

    const setAllOpenPanelsToLoading = () => {
        for (let i = 0; i < planAccordions.length; i++) {
            for (let mealType in planAccordions[i]) {
                if (getPlanAccordion(i, mealType, 'isOpen')){
                    setPlanAccordion(i, mealType, true, 'isLoading');
                }   
            }
        }
        
        setIsAnyPanelLoading(checkifAnyPanelIsLoading());
    };

    useEffect(() => {
        setIsAnyPanelLoading(checkifAnyPanelIsLoading());
    }, [planAccordions,checkifAnyPanelIsLoading]);
    
    

    const value = {
        planAccordionTemplate,
        planAccordions,
        setPlanAccordions,
        setPlanAccordion,
        setSpecialRequests,
        fetchPlan,
        getPlanAccordion,
        handleSubmit,
        recipeTemplate,
        isPopupOpen,
        isAnyPanelLoading,
        checkifAnyPanelIsFilled,
        setIsPopupOpen,
        demoCredits, 
        setDemoCredits
    };

    return (
        <PlanContext.Provider value={value}>
            {children}
        </PlanContext.Provider>
    );
};
