import React, { useRef, useState, useEffect} from 'react';
import './PlannerAllAccess.css';
import AccordionContainer from '../AccordionContainer/AccordionContainer.js';
import SubscribePopup from '../SubscribePopUp/SubscribePopUp.js';
import PlannerHeader from '../PlannerHeader/PlannerHeader.js';
import { usePlan } from '../../context/PlannerContext.js';
import { useAuth } from '../../context/AuthContext.js';
import { useNavigate } from 'react-router-dom';
import SavedView from '../SavedView/SavedView.js';
// import { useNavigate } from 'react-router-dom';
import NavBar from '../NavBar/NavBar.js';
import GenerateButton from '../GenerateButton/GenerateButton.js';


const PlannerAllAccess = ({ calendarRef }) => {

    const { user, isSubscribed } = useAuth();
    const navigator = useNavigate();
    useEffect(() => {
        if (!user || !isSubscribed) {
            navigator('/');
            return;
        }
    }, [user, isSubscribed, navigator]);

    const isAllAccess = true;

    const [isOnSavedPlan, setIsOnSavedPlan] = useState(false);

    return (
        <>
        <SubscribePopup/>
        
        <NavBar isAllAccess={isAllAccess} setIsOnSavedPlan={setIsOnSavedPlan} isOnSavedPlan={isOnSavedPlan}/>
        <div className="dead-space"></div>

        { !isOnSavedPlan ? <PlannerView calendarRef={calendarRef}/>
        : <SavedView setIsOnSavedPlan={setIsOnSavedPlan}/>}
        
        <div className="dead-space"></div>
        </>
    );
};


const PlannerView = ({calendarRef}) => {
    const { planAccordions, 
        loadingText,
        handleSubmit } = usePlan();

    const dayRefs = useRef([]);
    const isAllAccess = true;
    return (
        <div className="main-section" id="calendar-section" ref={calendarRef} >
            
    
            <form className="form" onSubmit={handleSubmit}>
                <PlannerHeader 
                    loadingText={loadingText} 
                    handleSubmit={handleSubmit}/>
                
                <GenerateButton isAllAccess={isAllAccess}/>
                
                <div className="text-box-container">
                    {planAccordions.map((_, index) => (
                        <AccordionContainer
                            key={index+1} 
                            dayNumber={index+1}
                            ref={el => dayRefs.current[index] = el}
                        />
                    ))}
                </div>
            </form>
        </div>
    )
};

export default PlannerAllAccess;