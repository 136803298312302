import React, { useState, useEffect, useRef } from 'react';
import './DemoPlanner.css';
import AccordionContainer from '../AccordionContainer/AccordionContainer.js';
import SubscribePopup from '../SubscribePopUp/SubscribePopUp.js';
import PlannerHeader from '../PlannerHeader/PlannerHeader.js';
import { usePlan } from '../../context/PlannerContext.js';
// import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext.js';
import NavBar from '../NavBar/NavBar.js';
import GenerateButton from '../GenerateButton/GenerateButton.js';


const DemoPlanner = ({ calendarRef }) => {
    
    const { planAccordions, 
            loadingText,
            handleSubmit, demoCredits, setIsPopupOpen} = usePlan();

    const { user, isSubscribed } = useAuth();
    const isAllAccess = false;
    
    const [isOnPlanner, setIsOnPlanner] = useState(false);
    const dayRefs = useRef([]);

    useEffect(() => {

        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 600) {
                setIsOnPlanner(true);
            } else {
                setIsOnPlanner(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [isSubscribed, user, setIsPopupOpen]);

    


    return (
        <>
        <SubscribePopup/>
        
        { isOnPlanner && <NavBar isAllAccess={isAllAccess}/>} 
        <div className="explainer-bar">
                <h1 className="explainer-text">Leave Special Request Blank For A Surprise Or Be Ultra Specific!</h1>
        </div>
        <div className="demo-credit-bar"> 

            <h1 className="demo-credit-text">Demo Credits: {demoCredits}/36</h1>
        </div>

        <div className="calendar-section" id="calendar-section" ref={calendarRef} >
            
            <form className="form" onSubmit={handleSubmit}>
                {isOnPlanner && <PlannerHeader 
                    loadingText={loadingText} 
                    handleSubmit={handleSubmit}/>
                }
                {isOnPlanner && <GenerateButton/>}

                <div className="text-box-container">
                    {planAccordions.map((_, index) => (
                        <AccordionContainer
                            key={index+1} 
                            dayNumber={index+1}
                            ref={el => dayRefs.current[index] = el}
                        />
                    ))}
                </div>
            </form>
        </div>
        <div className="dead-space"></div>
        </>
    );
};

export default DemoPlanner;