import React from 'react';
import Layout from '../Layout/Layout';
import SignUpForm from '../SignUpForm/SignUpForm.js';
import SignInForm from '../SignInForm/SignInForm.js';
import './App.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Recipe from '../ContactUs/ContactUs.js';
import { AuthProvider } from '../../context/AuthContext.js';
import { PlanProvider } from '../../context/PlannerContext.js';
import PlannerAllAccess from '../Planner/PlannerAllAccess.js';
import HeroPage from "../HeroPage/HeroPage";
import DemoPlanner from "../DemoPlanner/DemoPlanner.js";
import RecoverPassword from "../RecoverPassword/RecoverPassword.js";


function App() {

    console.log("render App.js")

    const calendarRef = React.useRef(null);

    return (
        <Router>
            <AuthProvider>
                <PlanProvider>
                    <Routes>
                        <Route path="/" element={<Layout/>}>
                            <Route index element={
                            <>
                                <HeroPage calendarRef={calendarRef}/>
                                <DemoPlanner calendarRef={calendarRef}/>
                            </>
                            }/>
                            <Route path="SignUp" element={<SignUpForm />} />
                            <Route path="SignIn" element={<SignInForm />} />
                            <Route path="AboutUs" element={<Recipe />} />
                            <Route path="RecoverPassword" element={<RecoverPassword/>}/>
                        </Route>
                        <Route path="/Planner" element={<PlannerAllAccess />}/>
                    </Routes>
                </PlanProvider>
            </AuthProvider>
        </Router>
    );
}

export default App;