import React, { createContext, useContext, useState, useEffect, useCallback} from 'react';
import axios from 'axios';

const AuthContext = createContext();


export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isSubscribed, setIsSubscribed] = useState(false); 

    // Function to log out the user
    const logout = useCallback(() => {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        setUser(null);
    },[]);

    const refreshAccessToken = useCallback(async () => {
        if (!localStorage.getItem('refresh')) {
            return;
        }
        try {
            const response = await axios.post('https://mealplannerapi.azurewebsites.net/api/token/refresh/', { refresh: localStorage.getItem('refresh') });
            localStorage.setItem('access', response.data.access);
            setUser(response.data.username);
            checkSubscriptionStatus(response.data.username);
        } catch (error) {
            console.error('Token refresh error:', error.response);
            logout();
        }
    },[logout]);
    
    

    console.log("render AuthContext.js")
    

    const login = async (username_or_email, password) => {
        try {
            const response = await axios.post('https://mealplannerapi.azurewebsites.net/api/login/', { username_or_email, password });
            localStorage.setItem('access', response.data.access);
            localStorage.setItem('refresh', response.data.refresh);
            console.log(response.data)
            setUser(response.data.user);
            checkSubscriptionStatus(response.data.user);
        } catch (error) {
            throw error;
        }
    };

    const checkSubscriptionStatus = async (username) => {
        try {
            const token = localStorage.getItem('access');
            console.log(username);
            const response = await axios.get(`https://mealplannerapi.azurewebsites.net/api/checkSub/${username}/`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            console.log(response);
            setIsSubscribed(response.data.active_subscription);
        } catch (error) {
            console.error('Subscription status check error:', error.response || error);
            setIsSubscribed(false); // Consider the user as not subscribed if the check fails
        }
    };
    
    
    
    useEffect(() => {
     
        refreshAccessToken();
    });


    return (
        <AuthContext.Provider value={{ user, login, logout, refreshAccessToken, isSubscribed}}>
            {children}
        </AuthContext.Provider>
    );
};
