import './SavedHeader.css'
import { Link } from 'react-router-dom';

const SavedHeader = ({saveCurrentPlan}) => {
    return (
        <header className="saved-header">
            <Link to="/" className='title'>
                    <h1 className="mini-title">Lp</h1>
            </Link>
            <div className="saved-header-content">
            
                <button onMouseDown={saveCurrentPlan} className="save-current-plan"> Save Current Plan </button>
            
            </div>
        </header>
    )
};

export default SavedHeader;